import React from 'react'
import FAQ from 'src/components/Faq/index'

type Faq = {
  faq: {question: string;
  answer: string;}[];
}

const FAQComponent = ({ faq }: Faq) => {
  return (
    <section className='py-5 bg-grayscale--100'>
      <div className='container py-lg-5'>
        <div className='row justify-content-center'>
          <div className='col-12 mb-4 text-center'>
            <h2 className='fs-24 lh-30 fs-md-40 lh-md-50 text-grayscale--500 mb-md-3'>Perguntas frequentes</h2>
          </div>
          <div className='col-12 col-lg-10 col-xl-8'>
            <FAQ answerData={faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} searchBg='#fff' />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FAQComponent
