import React, { Fragment } from 'react'
import Img from 'gatsby-image'
import ScrollTo from 'src/components/ScrollTo'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import LinksPF from '../../assets/data/links-pf-acessar-conta.json'
import usePageQuery from 'src/pages/volte-para-o-inter/pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Container, LinksList } from './style'

type LinksProps = {
  title: string;
  image: string;
  altImage: string;
  links: {
    motivo: string;
    url: string;
  }[];
}

const Links = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()
  const pageText = LinksPF

  function handleClickTag (item: {motivo: string; url: string}) {
    sendDatalayerEvent({
      section: 'dobra_1',
      section_name: 'O que aconteceu?',
      element_action: 'click scroll',
      element_name: item.motivo,
    })
  }

  return (
    <Container className='pt-4 pb-5 pt-md-0 d-flex position-relative justify-content-md-between align-items-center'>
      <div className='container py-xl-5'>
        <div className='row align-items-center justify-content-md-between'>
          {pageText.map((item: LinksProps, index: number) => (
            <Fragment key={index}>
              <div className='col-12 col-md-5'>
                <Img fluid={data[item.image].fluid} alt={item.altImage} />
              </div>
              <div className='col-12 col-md-6'>
                <h2 className='fs-32 lh-40 fs-xl-48 lh-xl-60 text-grayscale--500'>
                  {item.title}
                </h2>
                <LinksList className='pl-0'>
                  { Object.values(item.links).map((value: {motivo: string; url: string}) => (
                    <div key={value.motivo} onClick={() => handleClickTag(value)} className='my-3'>
                      <ScrollTo to={value.url} title={value.motivo}>
                        <li className='fs-16 lh-20 card-link pl-4 pr-3 py-4 d-flex align-items-center justify-content-between'>
                          <div>{value.motivo}</div>
                          <div>
                            <OrangeIcon size='MD' color='#FF7A00' icon='chevron-right' />
                          </div>
                        </li>
                      </ScrollTo>
                    </div>
                    ))
                  }
                </LinksList>
              </div>
            </Fragment>
            ))
          }
        </div>
      </div>
    </Container>
 )
}

Links.defaultProps = {
  page: 'PF',
}

export default Links
