import React, { useState } from 'react'

// components
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import AtivacaoModal from 'src/components/UI/Forms/InterAtivacaoClientesForm'

// Sections
import Links from './sections/links/_index'
import ComoAtualizarSuaSenha from './sections/comoAtualizarSuaSenha/_index'
import AcessoEmailCelular from './sections/acessoEmailCelular/_index'
import ContaPraGente from './sections/contaPraGente/_index'
import HelpCenter from '../helpCenter/_index'
import ContaDigitalCompleta from './sections/contaDigitalCompleta/_index'
import Beneficios from './sections/beneficios/_index'
import OfertasExclusivas from './sections/ofertasExclusivas/_index'
import Faq from './sections/faq/_index'

// hooks
import useDomReady from 'src/hooks/window/useDomReady'

import pageContext from './pageContext.json'
import { Wrapper } from './style'

const OutroMotivo = () => {
  const domReady = useDomReady()
  const [ openModal, setOpenModal ] = useState(false)

  const openAccountPJFormModal = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <AtivacaoModal closeModal={() => setOpenModal(false)} />
    </Modal>
  )
    return (
      <Wrapper>
        <Layout pageContext={pageContext}>
          {openAccountPJFormModal}
          <Links />
          <ComoAtualizarSuaSenha />
          <AcessoEmailCelular />
          <ContaPraGente section='dobra_04' />
          <HelpCenter />
          <ContaDigitalCompleta />
          <Beneficios />
          <OfertasExclusivas setOpenModal={setOpenModal} />
          <Faq faq={pageContext.structuredData.faq} />
        </Layout>
      </Wrapper>
    )
}

export default OutroMotivo
