import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  @media ${device.tablet} {
    min-height: 368px;
  }
  @media ${device.desktopLG} {
    min-height: 536px;
  }
  @media ${device.desktopXL} {
    min-height: 686px;
  }

  a {
    &.btn {
      height: 48px;
      padding-top: 5px;

      @media ${device.desktopXL} {
       max-width: 456px;
      }
    }
  }
`
