import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import textPF from '../../assets/data/acesso-email-celular.json'

import usePageQuery from '../../../pageQuery'
import { Section } from './style'

const AcessoEmailCelular = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
    const data = usePageQuery()
    return (
      <Section className='py-5 py-md-0 d-md-flex position-relative align-items-md-center justify-content-md-between' id='nao-tenho-mais-acesso'>
        <div className='container'>
          <div className='row align-items-md-center justify-content-md-between'>
            <div className='col-12 col-md-5'>
              <Img fluid={data[textPF.image].fluid} alt={textPF.altImage} />
            </div>
            <div className='col-12 col-md-6 col-lg-5 col-xl-6 px-md-0'>
              <h1
                className='fs-32 lh-40 fs-xl-48 lh-xl-60 text-grayscale--500 pt-5 pb-4 pt-md-0 mb-md-0 fw-600'
                dangerouslySetInnerHTML={{ __html: textPF.title }}
              />
              <div onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_3',
                  section_name: 'Não tem mais acesso ao email e celular cadastrados?',
                  element_action: 'click button',
                  element_name: 'Alterar telefone e e-mail',
                  c_page: window.location.href,
                })
              }}
              >
                <Link
                  className='btn btn-orange--extra text-none rounded-2 mt-2'
                  target='_blank'
                  title='Alterar telefone e e-mail'
                  to='https://ajuda.bancointer.com.br/pt-BR/articles/5599454-preciso-alterar-telefone-e-e-mail-cadastrados-e-possivel'
                > Alterar telefone e e-mail
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Section>
    )
  }

  AcessoEmailCelular.defaultProps = {
    page: 'PF',
  }

  export default AcessoEmailCelular
