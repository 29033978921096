import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      EsqueciMinhaSenha: imageSharp(fluid: {originalName: { regex: "/atualizar-sua-senha-step-01-esqueci-minha-senha/" }}) {
          fluid(maxWidth: 456, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      AutenticacaoCVV: imageSharp(fluid: {originalName: { regex: "/atualizar-sua-senha-step-02-autenticacao-cvv/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      RevisarFoto: imageSharp(fluid: {originalName: { regex: "/atualizar-sua-senha-step-03-revisar-foto/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      AutenticacaoSMS: imageSharp(fluid: {originalName: { regex: "/atualizar-sua-senha-step-04-autenticao-sms/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      SenhaForte: imageSharp(fluid: {originalName: { regex: "/atualizar-sua-senha-step-05-senha-forte/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      NovaSenhaGerada: imageSharp(fluid: {originalName: { regex: "/atualizar-sua-senha-step-06-nova-senha-gerada/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
